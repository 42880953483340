import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  MenuItem,
} from '@mui/material';

const TemplatePickerModal = ({
  open,
  onClose,
  onSelectTemplate,
  templates,
}) => {
  const [selectedCategory, setSelectedCategory] = useState('');

  // Predefined categories
  const predefinedCategories = [
    'meetings',
    'agendas',
    'blogs',
    'articles',
    'events',
    'promotions',
    'menus',
    'announcements',
  ];

  // Function to filter templates by category
  const filteredTemplates = selectedCategory
    ? templates.filter((template) =>
        template.categories.includes(selectedCategory)
      )
    : templates;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh',
          overflowY: 'auto',
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2">
          Select a Template
        </Typography>

        {/* Category Filter */}
        <TextField
          select
          label="Filter by Category"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        >
          <MenuItem value="">
            <em>All Categories</em>
          </MenuItem>
          {predefinedCategories.map((category, index) => (
            <MenuItem key={index} value={category}>
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </MenuItem>
          ))}
        </TextField>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {filteredTemplates.map((template, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  p: 2,
                  border: '1px solid #ccc',
                  borderRadius: 1,
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#f0f0f0' },
                }}
                onClick={() => onSelectTemplate(template)}
              >
                <Typography variant="subtitle1">{template.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {template.description}
                </Typography>
                {/* Template Preview */}
                <Box
                  sx={{
                    mt: 2,
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    height: 200,
                    overflow: 'auto',
                    background: '#f7f7f7',
                    position: 'relative',
                  }}
                >
                  <Box
                    sx={{
                      transform: 'scale(0.6)',
                      transformOrigin: 'top left',
                      width: '166.67%',
                      height: 'auto',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                    dangerouslySetInnerHTML={{ __html: template.html }}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={onClose}
          sx={{ mt: 2 }}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default TemplatePickerModal;
