//import Header from './ui/header.component';
import Navigate from './ui/navigate.component';

//import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@mui/material/styles';

//import {ConnectedRouter} from 'connected-react-router';
import { Parse } from 'parse';
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import { FlagsProvider } from 'react-feature-flags';
import Demo from './ui/demo.component';

import { Create as CreateTag } from './tags/tag.container';
import { All as Analytics } from './analytic/analytic.container';
import { All as Channels } from './channels/channel.container';
import { All as Help } from './help/help.container';
import { Update as UpdateUser } from './users/user.container';
import { All as Dashboard } from './dashboard/dashboard.container';
import { All as Tags } from './tags/tag.container';
import { Update as UpdateTag } from './tags/tag.container';
import { Create as Session } from './sessions/session.container';
import { header as Header } from './ui/ui.container';
import { All as Media } from './media/media.container';
import { AllByBeacon as QrTag } from './tags/tag.container';
import { CreateQr as CreateQrCode } from './beacons/beacon.container';
import { Create as CreateBeacon } from './beacons/beacon.container';
import { IndexQr as QrIndex } from './beacons/beacon.container';
import { QrDetails as DetailsQr } from './beacons/beacon.container';
import { uiBlock as UiBlock } from './ui/ui.container';
import { All as BeaconIndex } from './beacons/beacon.container';
import { All as Integrations } from './connectors/connector.container';
import { ForgotPassword as PasswordReset } from './ui/ui.container';
import { CreateNFCTag as LifeTapCreate } from './nfc/nfc.new.component';
import UserProfileForm from './ui/userProfile.component';
import TagManager from 'react-gtm-module/dist/TagManager';
import UserNewLifeTap from './users/user.new.lifetap';

import { Snackbar } from 'react-redux-snackbar';

import ReactGA from 'react-ga';
import { UserNewStripeEmbed } from './users/user.new.stripe.embed';
import { initializeApp } from 'firebase/app';

import { getMessaging, onMessage } from 'firebase/messaging';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_WEB_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
};

// initialize Firebase messaging
const app = initializeApp(config);

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
const messaging = getMessaging(app);

onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

const trackingId = process.env.REACT_APP_GOOGLE_ANALYTICS;
ReactGA.initialize(trackingId);
const tagManagerArgs = {
  gtmId: 'GTM-KPJ2JG3',
};

TagManager.initialize(tagManagerArgs);

Parse.serverURL = process.env.REACT_APP_SERVER_LOCAL
  ? `${process.env.REACT_APP_SERVER_LOCAL}/parse`
  : `${process.env.REACT_APP_API_URL}/parse`;
Parse.initialize(process.env.REACT_APP_API_KEY, 'hahahahahaha');
window.Parse = Parse;
let hideNav = 'none';
let flags = [];

//hide drawer
if (Parse.User.current()) {
  hideNav = '';
  ReactGA.set({
    userId: Parse.User.current(),
  });

  const isPro =
    Parse.User.current().get('accountPlan') === 'plan_HHqLLnGbAL8lx2' ||
    Parse.User.current().get('accountPlan') === 'plan_HHqTYqPUkMuQA6'
      ? true
      : false;

  const isBeta =
    process.env.REACT_APP_STAGE == 'DEV' ||
    process.env.REACT_APP_STAGE == 'DEVELOP' ||
    process.env.REACT_APP_STAGE == 'LOCAL'
      ? true
      : false;

  flags = [
    { name: 'pro', isActive: isPro },
    { name: 'beta', isActive: isBeta },
  ];

  window.pendo.initialize({
    visitor: {
      id: Parse.User.current().id, // Required if user is logged in
      full_name: Parse.User.current().get('company'),
    },

    account: {
      id: Parse.User.current().id, // Highly recommended
      is_paying: true, // Recommended if using Pendo Feedback
      planLevel: Parse.User.current().get('accountPlan') || 'standard', // Optional
    },
  });

  window.Intercom('boot', {
    app_id: 'wn943hcg',
    email: Parse.User.current().get('email'),
    user_id: Parse.User.current().id,
    created_at: Date.now(),
  });
}

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  toolbar: {
    [theme.breakpoints.down('sm')]: {
      diplay: 'none',
    },
    ...theme.mixins.toolbar,
  },

  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    color: '#fff',
    ...theme.mixins.toolbar,
  },
  appLogo: {
    marginTop: 20,
    borderRadius: '100%',
    borderColor: '#FFFFFF',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderImageWidth: '45%',
    height: 40,
    width: 40,
    pointerEvents: 'none',
    align: 'left',
  },
  logoText: {
    color: 'white',
    position: 'relative',
    top: 14,
    display: 'inline',
    left: 8,
  },
  root: {
    height: '100%',
    display: 'flex',
  },
  notification: {
    color: '#ccc',
  },
  appBar: {
    marginLeft: '224px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - 224px)`,
    },
    color: '#000',
    background: theme.palette.background.default,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: '224px',
      flexShrink: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    display: hideNav,
  },
  content: {
    flexGrow: 1,
    height: '100%',
    backgroundColor: '#F0F4FF',
    // padding: theme.spacing(3),
    //   backgroundColor: theme.palette.background.paper
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  login: {
    marginTop: 140,
    [theme.breakpoints.down('sm')]: {
      marginTop: 100,
    },
  },
  showView: {
    textAlign: 'left',
    verticalAlign: 'top',
    fontSize: '14pt',
    fontWeight: 'normal',
    fontFamily: 'Open Sans serif',
    marginTop: Parse.User.current() ? 60 : 0,
    padding: Parse.User.current() ? 20 : 0,
  },
  separator: {
    width: 1,
    height: 25,
    marginLeft: '1.5rem',
    marginRight: '1.5rem',
    borderLeft: '1px solid #000',
  },
}));

export default function App(props) {
  const { history } = props;
  window.dataLayer.push({
    event: 'pageview',
  });

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
  });

  const classes = useStyles();
  const theme = useTheme();
  const [profileMenuIsOpen, setProfileMenuIsOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const openProfileMenu = () => setProfileMenuIsOpen(true);
  const closeProfileMenu = () => setProfileMenuIsOpen(false);
  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <Router>
      <div className={classes.root}>
        {/* CSS Baseline */}
        <CssBaseline />
        {Parse.User.current() && (
          <Header
            profileMenuIsOpen={profileMenuIsOpen}
            handleDrawerToggle={handleDrawerToggle}
            openProfileMenu={openProfileMenu}
            closeProfileMenu={closeProfileMenu}
            classes={classes}
          />
        )}

        {Parse.User.current() && (
          <Navigate
            sentInClasses={classes}
            theme={theme}
            history={history}
            handleDrawerToggle={handleDrawerToggle}
            mobileOpen={mobileOpen}
          />
        )}

        {/* Main content */}
        <main className={classes.content} color={'secondary'} history={history}>
          <div className={classes.showView}>
            <Snackbar />
            <UiBlock />
            <Switch>
              <Route path="/qrtags/:id" component={QrTag} />
              <Route path="/push/:id" component={QrTag} />
              <Route path="/ltcard/:id" component={UserProfileForm} />
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/tags" component={Tags} />
              <PrivateRoute path="/analytics" component={Analytics} />
              <PrivateRoute exact path="/tags/create" component={CreateTag} />
              <PrivateRoute exact path="/media" component={Media} />
              <PrivateRoute path="/channels" component={Channels} />
              <PrivateRoute exact path="/qrcode" component={QrIndex} />
              <PrivateRoute
                exact
                path="/qrcode/create"
                component={CreateQrCode}
              />
              <PrivateRoute path="/qrcode/details/:id" component={DetailsQr} />
              <PrivateRoute path="/tag/edit/:id" component={UpdateTag} />
              <PrivateRoute
                exact
                path="/beacon/create"
                component={CreateBeacon}
              />
              <PrivateRoute path="/help" component={Help} />
              <PrivateRoute path="/user/edit" component={UpdateUser} />
              <PrivateRoute path="/beacons" component={BeaconIndex} />
              {/* <Flags authorizedFlags={['beta']}> */}
              <PrivateRoute path="/integrations" component={Integrations} />
              {/* </Flags> */}
              <Route path="/register" component={UserNewStripeEmbed} />
              <Route path="/lifetapCreate" component={LifeTapCreate} />
              <Route path="/lifetapregistration" component={UserNewLifeTap} />
              <Route path="/resetpassword" component={PasswordReset} />
              <Route path="/demo" component={Demo} />

              {/* <Route path="/register" component={createUser}/> */}
            </Switch>
          </div>
        </main>
      </div>
    </Router>
  );
}

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        Parse.User.current() ? (
          <FlagsProvider value={flags}>
            <Component {...props} />
          </FlagsProvider>
        ) : (
          <Session {...props} />
        )
      }
    />
  );
}
