import React, { useEffect, useState } from 'react';
import FilterByBeacon from '../tags/tag.filterByBeacon';

import {
  Container,
  TextField,
  Button,
  Typography,
  Grid,
  Paper,
  Avatar,
  Link,
} from '@mui/material';

import Parse from 'parse';

function UserProfileForm({ match }) {
  const [tag, setTag] = useState([]);
  const [formData, setFormData] = useState({
    profileImage: '',
    fullName: '',
    jobTitle: '',
    company: '',
    quote: '',
    email: '',
    phone: '',
    instagram: '',
    website: '',
    meetingUrl: '',
  });
  const { id } = match.params;
  console.log('id ', id);

  useEffect(async () => {
    let beacon = await Parse.Cloud.run('getTagsByBeaconId', {
      id: id,
    });

    console.log('beacon  ', beacon);
    setTag(beacon);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Data:', formData);
    let params = { profileInfo: formData };
    Parse.Cloud.run('generateProfileTag', params);
    // You can handle form submission here, e.g., send it to a server or update a component
  };

  return (
    <div>
      <Container maxWidth="sm">
        {!tag[0] ? (
          <Paper
            elevation={3}
            style={{
              padding: '20px',
              backgroundColor: '#1e1e1e',
              color: '#fff',
            }}
          >
            <Typography variant="h5" gutterBottom>
              Fill out your Profile Information
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Profile Image URL"
                    name="profileImage"
                    value={formData.profileImage}
                    onChange={handleChange}
                    placeholder="https://example.com/profile.jpg"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder="Julianna Mickle"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Job Title"
                    name="jobTitle"
                    value={formData.jobTitle}
                    onChange={handleChange}
                    placeholder="Account Executive"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Company"
                    name="company"
                    value={formData.company}
                    onChange={handleChange}
                    placeholder="LifeTagger"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Favorite Quote"
                    name="quote"
                    value={formData.quote}
                    onChange={handleChange}
                    placeholder="There is nothing more truly artistic than to love people - Vincent Van Gogh"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="julianna@lifetaggerapp.com"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="774-368-6974"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Instagram URL"
                    name="instagram"
                    value={formData.instagram}
                    onChange={handleChange}
                    placeholder="https://www.instagram.com/username"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Website"
                    name="website"
                    value={formData.website}
                    onChange={handleChange}
                    placeholder="https://lifetagger.io"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Meeting Scheduler URL"
                    name="meetingUrl"
                    value={formData.meetingUrl}
                    onChange={handleChange}
                    placeholder="https://meetings.hubspot.com/username"
                    InputLabelProps={{ style: { color: '#fff' } }}
                    InputProps={{ style: { color: '#fff' } }}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        ) : (
          <FilterByBeacon id={tag[0].beaconId} />
        )}

        {/* Displaying the profile preview */}
        {formData.fullName && (
          <Paper
            elevation={3}
            style={{
              marginTop: '20px',
              backgroundColor: '#1e1e1e',
              color: '#fff',
            }}
          >
            <div className="template-container" style={{ padding: '20px' }}>
              <div
                className="profile-section"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '20px',
                }}
              >
                <Avatar
                  src={formData.profileImage}
                  style={{ width: '80px', height: '80px', marginRight: '15px' }}
                />
                <div className="profile-info">
                  <Typography variant="h6">{formData.fullName}</Typography>
                  <Typography variant="body2">
                    <em>{formData.quote}</em>
                  </Typography>
                  <Typography variant="body2">
                    {formData.jobTitle}, {formData.company}
                  </Typography>
                </div>
              </div>
              <Button
                fullWidth
                variant="contained"
                style={{ backgroundColor: '#4CAF50', color: '#fff' }}
              >
                Save Contact
              </Button>
              <Link href={formData.instagram} target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#2c2c2c',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                >
                  Instagram
                </Button>
              </Link>
              <Link href={`tel:${formData.phone}`} target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#2c2c2c',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                >
                  {formData.phone}
                </Button>
              </Link>
              <Link href={`mailto:${formData.email}`} target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#2c2c2c',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                >
                  Send me an Email
                </Button>
              </Link>
              <Link href={formData.website} target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#2c2c2c',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                >
                  {formData.website}
                </Button>
              </Link>
              <Link href={formData.meetingUrl} target="_blank">
                <Button
                  fullWidth
                  variant="contained"
                  style={{
                    backgroundColor: '#2c2c2c',
                    marginTop: '10px',
                    color: '#fff',
                  }}
                >
                  Schedule a Meeting
                </Button>
              </Link>
            </div>
          </Paper>
        )}
      </Container>
    </div>
  );
}

export default UserProfileForm;
