import React, { useRef, useEffect, useState } from 'react';
import TemplatePickerModal from '../tagTemplates/tagTemplate.index.component';
import { Editor } from '@tinymce/tinymce-react';
import { Parse } from 'parse';
import * as R from 'ramda';
import { Button } from '@mui/material';

const wrapperStyle = `
  padding: 10px;
  border: 1px dashed #ccc;
  background: #eee;
`;

export function EditorComponent(props) {
  const [tagTemplates, setTagTemplates] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [dynamicConfig, setDynamicConfig] = useState(null);
  let { defaultValue, setMessageText } = props;

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleSelectTemplate = (template) => {
    setSelectedTemplate(template);
    setModalOpen(false);
    if (template.tinymce) {
      setDynamicConfig(template.tinymce);
    }
    const htmlContent = template.html.replace(/^"|"$/g, '');
    // defaultValue = template.html;
    setMessageText({
      html: htmlContent,
    });
    // You can now load the TinyMCE configuration and content for the selected template

    console.log('selected templ  ', selectedTemplate);
    console.log('dynamic Template:', dynamicConfig);
  };

  useEffect(async () => {
    const theTemplates = await Parse.Cloud.run('getTagTemplates', {});
    setTagTemplates(theTemplates);
  }, []);

  window.document.addEventListener('DOMContentLoaded', function () {
    window.document.body.addEventListener('click', function (event) {
      var target = event.target;
      if (
        target.tagName.toLowerCase() === 'a' &&
        target.hasAttribute('venmo-href')
      ) {
        event.preventDefault();
        var venmoLink = target.getAttribute('venmo-href');
        window.location.href = venmoLink;
      }
    });
  });

  const editorRef = useRef(null);
  window.tinyEditorRef = editorRef;

  function handleDrop(event) {
    const { text, id } = JSON.parse(
      event.dataTransfer.getData('integrationData')
    );

    const editor = editorRef.current.editor;

    if (id.includes('all-')) {
      const tag = id.replace('all-', '');

      // Insert wrapper element
      insertAllWrapper(tag, editor);
    } else {
      const content = `<div class="${id}">{{${text}}}</div>`;

      // Encapsulate text in brackets
      // Get editor instance

      editor.insertContent(content);
    }
  }

  function insertAllWrapper(tag, editor) {
    const openTag = `<div class="all-wrapper" style="${wrapperStyle}">`;
    const closeTag = `</div>`;

    editor.insertContent(`${openTag}<${tag}></${tag}>${closeTag}`);
  }

  // Later insertions add content between tags
  // function insertBetweenAllTags(content) {
  //   editor.execCommand('mceInsertContent', false, content, {
  //     wrap: true,
  //     select: true,
  //   });
  // }

  const log = () => {
    if (editorRef.current) {
      const html = editorRef.current.editor.getContent({ format: 'html' });
      const text = editorRef.current.editor.getContent({ format: 'text' });
      html.replace(/ style="height: 633px; width: 100%;"/g, '');
      html.replace('editor-iframe', '');

      setMessageText({
        html: html,
        text: text,
      });
    }
  };

  return (
    <div>
      <Button variant="contained" onClick={handleOpenModal}>
        Use Template
      </Button>

      <TemplatePickerModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSelectTemplate={handleSelectTemplate}
        templates={tagTemplates}
      />
      <Editor
        ref={editorRef}
        apiKey="tlrl75bwhqd4p6eybz8hek8rv4sv9dqk8hxc3faag08x7uny"
        //onBlur={log}
        onEditorChange={log}
        value={defaultValue}
        init={{
          toolbar_mode: 'wrap',
          toolbar_location: 'top',
          table_sizing_mode: 'relative',
          content_scripts: [
            'https://unpkg.com/react-color@2.19.3/dist/react-color.min.js',
          ],
          image_class_list: [{ title: 'Responsive', value: 'img-responsive' }],
          height: '60vh',
          branding: false,
          allow_unsafe_link_target: true,
          extended_valid_protocols: 'venmo',
          extended_valid_elements:
            'style,link[href|rel],script,canvas,i[class],a[href|target=_blank|rel|download]',
          custom_elements: 'style,link,~link,script,~script,canvas,~canvas',
          media_live_embeds: true,
          file_picker_types: 'image media',
          allow_data_urls: true,
          allow_script_urls: true,
          plugins: [
            'lt_form_embed_plugin',
            'lt_pay_buttons',
            'lt_integrations',
            'lt_tracking_pixels',
            'lt_pdf_uploader',
            'lt_iframe_plugin',
            'lt_social_buttons',
            'lt_ai_prompt',
            'lt_background_image_picker',
            'lt_back_ground_color_picker',
            'image',
            'media',
            'emoticons',
            'table',
            'searchreplace',
            'fullscreen',
            'preview',
            'link',
            'lists',
            'code',
            'noneditable',
          ],
          toolbar: [
            'fontfamily | link bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat undo redo | image | lt_pdf_uploader lt_iframe_plugin | lt_pay_buttons lt_social_buttons lt_tracking_pixels lt_back_ground_color_picker lt_ai_prompt lt_form_embed_plugin code',
          ],
          external_plugins: {
            // Specify the path to your local plugin file
            lt_paypal_button: '/lt_pay_buttons.js',
            lt_tracking_pixels: '/lt_pixel_insert.js',
            lt_pdf_uploader: '/lt_pdf_uploader.js',
            lt_social_buttons: '/lt_social_buttons.js',
            lt_iframe_plugin: '/lt_iframe_plugin.js',
            lt_background_image_picker: '/lt_background_image_picker.js',
            lt_back_ground_color_picker: '/lt_back_ground_color_picker.js',
            lt_ai_prompt: '/lt_ai_prompt.js',
            lt_form_embed_plugin: '/lt_form_embed_plugin.js',
          },
          content_style: `
          	.content-container {
          		max-width: 100%;
              padding-left: 2px;
              padding-right: 2px;
              height: auto;
          		margin: 0 auto;
          	}

          	.content-container img {
          		max-width: 100%;
          		height: auto;
          	}

            .content-container video {
          		max-width: 100%;
          		height: auto;
          	}

          	.content-container iframe {
          		max-width: 100%;
          	}

          	@media (min-width: 768px) {
          		.content-container {
          			max-width: 720px;
          		}
          	}

          	@media (min-width: 992px) {
          		.content-container {
          			max-width: 960px;
          		}
          	}

            .link-preview {
              display: flex;
              flex-direction: column;
              border: 1px solid #ddd;
              border-radius: 8px;
              overflow: hidden;
              box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
              margin: 10px 0;
              max-width: 600px;
            }
            .link-preview-image {
              width: 100%;
              height: 200px;
              background-size: cover;
              background-position: center;
            }
            .link-preview-content {
              padding: 15px;
            }
            .link-preview-title {
              margin: 0 0 10px;
              font-size: 18px;
              font-weight: bold;
            }
            .link-preview-description {
              margin: 0 0 15px;
              font-size: 14px;
              color: #555;
            }
            .link-preview-url {
              display: inline-block;
              padding: 10px 15px;
              background-color: #007bff;
              color: white;
              text-decoration: none;
              border-radius: 4px;
              font-size: 14px;
            }
            .link-preview-url:hover {
              background-color: #0056b3;
            }

          	@media (min-width: 1200px) {
          		.content-container {
          			max-width: 1140px;
          		}
          	}
            
          `,
          /* Add fonts from google to the list of fonts that can be used in the editor*/
          font_family_formats:
            'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Pacifico=Pacifico,cursive; Concert One=concert one,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Lato=lato,sans-serif; Montserrat=Montserrat,sans-serif; Open Sans=open sans,sans-serif; Oswald=Oswald,sans-serif; Permanent Marker=Permanent Marker,cursive; Prompt=prompt,sans-serif; Raleway=Raleway,sans-serif; Roboto=Roboto,sans-serif; Slabo=slabo,sans-serif; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva;Libre Baskerville=Libre Baskerville,serif; Playfair Display=playfair display,serif;',
          content_css: [
            'https://fonts.googleapis.com/css?family=Roboto|Lato|Open+Sans|Montserrat|Oswald|Source+Sans+Pro|Slabo+27px|Raleway|Concert+One|Prompt',
            'https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css',
            'https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css',
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css',
            'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css',
            'https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Roboto:wght@400;700&display=swap',
            'https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Zilla+Slab:wght@700&display=swap',
            'https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&family=Open+Sans:wght@400;600&family=Pacifico&family=Montserrat:wght@700&display=swap',
            'https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap',
          ],

          setup: function (editor) {
            editor.on('BeforeSetContent', function (e) {
              let temp = document.createElement('div');

              //set temp's height to 100%
              temp.style.height = '100%';

              // Set div's innerHTML to editor content
              temp.innerHTML = e.content;

              let hasColorContainer = temp.querySelector('.color-container');

              // Get content container div from temp
              let contentContainer = temp.querySelector('.content-container');

              if (hasColorContainer) {
                e.content = `<div class="color-container" style="background-color: ${hasColorContainer.style.backgroundColor};"> ${hasColorContainer.innerHTML}</div>`;
              }

              // if (contentContainer && !hasColorContainer) {
              //   e.content = `<div class="content-container" style="padding-top: 17px; height: 100%;"> ${contentContainer.innerHTML}</div>`;
              // }

              console.log(
                'this has a content container in the editor ',
                contentContainer
              );
              if (!contentContainer && !hasColorContainer) {
                if (!R.isEmpty(e.content)) {
                  e.content =
                    '<div class="content-container">' + e.content + '</div>';
                }
              }
            });

            editor.on('drop', (event) => {
              // Prevent default drop behavior
              const text = event.dataTransfer.getData('integrationData');
              if (text) {
                handleDrop(event);
                event.preventDefault();
                return;
              }
            });
          },
          automatic_uploads: true,
          /* and here's our custom image picker*/
          file_picker_callback: (cb) => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*, video/*');

            input.addEventListener('change', (e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.addEventListener('load', async () => {
                /*
								  Note: Now we need to register the blob in TinyMCEs image blob
								  registry. In the next release this part hopefully won't be
								  necessary, as we are looking to handle it internally.
								*/

                let image = new Parse.File('image', file, file.type);
                await image.save();

                const id = 'blobid' + new Date().getTime();
                const blobCache =
                  editorRef.current.editor.editorUpload.blobCache;
                const base64 = reader.result.split(',')[1];
                const blobInfo = blobCache.create(id, file, base64);
                blobCache.add(blobInfo);

                /* call the callback and populate the Title field with the file name */
                cb(image.url(), { title: file.name });
              });
              reader.readAsDataURL(file);
            });

            input.click();
          },
        }}
      />
    </div>
  );
}
